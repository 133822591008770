import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Trading Solutions
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  <li className="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Trading Solutions
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                
                alt=""
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* About Start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src="img/Automotive.jpg" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Trading
              </div>
              <h1 className="mb-4">Automotive</h1>
              <p className="mb-4">
                <p>
                  Offering a swift, adaptable, and transparent supply chain
                  solution tailored to the automotive sector, {companyname}{" "}
                  stands ready. Leveraging its extensive global maritime and
                  inland network, {companyname} guarantees prompt and dependable
                  deliveries, no matter how intricate the supply chain may be.
                </p>

                <p>
                  The company boasts a wealth of experience and proficiency in
                  meeting the time-sensitive demands of automobile
                  manufacturers. Its capabilities extend to delivering
                  transportation and logistics solutions for a wide range of
                  automotive products, including spare parts, motorcycles,
                  e-mobility solutions, and oversized cargo.
                </p>

                <p>
                  From the initial stages of packing and loading to the final
                  stages of transportation and delivery, our company is equipped
                  to assist at every step of the shipping process. Our
                  commitment is to ensure that each task is executed efficiently
                  and punctually.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Trading
              </div>
              <h1 className="mb-4">Electronics</h1>
              <p className="mb-4">
                <p>
                  {companyname} extends a variety of logistics solutions
                  tailored to the electronics and high-tech sectors. These
                  encompass specialized courier services, warehousing, and
                  distribution. Our primary goal is to ensure the secure
                  transportation and rapid distribution of electronic goods
                  along the supply chain, all while guaranteeing punctual and
                  safe delivery to the final consumers.
                </p>

                <p>
                  For comprehensive logistics support, {companyname} offers
                  end-to-end solutions, encompassing global warehousing and
                  distribution services. Our main focus is delivering customized
                  logistics solutions for high-tech industries, empowering
                  businesses to achieve operational excellence and flexibility
                  while effectively meeting the demands of their end customers.
                </p>
              </p>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src="img/Electronics.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src="img/Special Cargo.jpg" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Trading
              </div>
              <h1 className="mb-4">Special Cargo</h1>
              <p className="mb-4">
                <p>
                  When dealing with the transportation of oversized cargo,
                  prioritizing safe and secure loading and unloading procedures
                  is paramount. Additionally, it's crucial to transport such
                  cargo in a manner that mitigates the potential for damage or
                  loss. Our team of seasoned experts specializes in managing the
                  logistics of oversized shipments. This includes securing the
                  requisite permits and efficiently coordinating with carriers
                  and other involved parties.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Trading
              </div>
              <h1 className="mb-4">Aviation and AOG</h1>
              <p className="mb-4">
                <p>
                  {companyname} delivers an extensive logistics solution
                  tailored to the aviation sector, with a special emphasis on
                  swiftly delivering aircraft parts for AOG (Aircraft on Ground)
                  situations. Our company's services encompass every facet of
                  the supply chain, ranging from the urgent shipment of spare
                  parts to the orchestration of logistics processes, providing
                  an all-in-one answer to aviation logistics requirements.
                </p>

                <p>
                  We provide transportation services for complete aircraft
                  engines and airplane noses, recognizing the paramount
                  importance of safety in transporting delicate and valuable
                  aircraft components. To ensure secure and cost-effective
                  shipping of aircraft engines, we deploy dedicated trucks with
                  ample capacity.
                </p>
              </p>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src="img/Aviation and AOG.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src="img/Pharmaceuticals.jpg" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Trading
              </div>
              <h1 className="mb-4">Pharmaceuticals</h1>
              <p className="mb-4">
                <p>
                  Pharmaceutical companies are increasingly turning to both sea
                  and air transportation methods to deliver critical medicines
                  and essential goods. The utmost importance lies in ensuring
                  the safe transport of these products while maintaining the
                  correct temperature to preserve their quality and integrity.
                </p>

                <p>
                  {companyname} stands out as a reliable and cost-effective
                  choice for pharmaceutical cargo shipping, thanks to our
                  adherence to Good Distribution Practice (GDP) standards and
                  our expansive global network.
                </p>

                <p>
                  Backed by over two decades of experience in handling sensitive
                  and hazardous cargo, our team of experts at {companyname} can
                  provide valuable assistance with cargo preparation and
                  packing, a particularly crucial aspect for pharmaceutical
                  shipments.
                </p>

                <p>
                  With our unmatched expertise and unwavering commitment to
                  safety, {companyname} emerges as the ideal choice for
                  pharmaceutical companies seeking secure and efficient cargo
                  transportation solutions.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Trading
              </div>
              <h1 className="mb-4">Cold logistics</h1>
              <p className="mb-4">content</p>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src="img/Cold logistics.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      <Footer />
    </>
  );
}
