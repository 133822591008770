import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Rail Freight
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  <li className="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Rail Freight
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                alt=""
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img
                  className="img-fluid"
                  src="img/Rail Freight.jpg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4">Rail Freight</h1>
              <p className="mb-4">
                <p>
                  Rail freight offers a cost-effective and convenient shipping
                  solution for your goods. However, concerns about reliability
                  have been prevalent in the industry. In today's business
                  landscape, where even the slightest delay can have dire
                  consequences, we can provide you with a competitive advantage
                  by offering a dependable and cost-effective rail option. Our
                  rail freight services are designed to meet your specific needs
                  and ensure the timely delivery of your products.
                </p>

                <p>
                  We understand the importance of protecting perishable goods
                  from extreme temperatures, which is why we offer
                  climate-controlled trailers for items such as produce,
                  flowers, poultry, beverages, and more. These specialized
                  trailers provide the necessary protection from heat or cold,
                  preserving the quality of your perishable products during
                  transit. Additionally, we handle non-perishable or general
                  freight with seamless efficiency, ensuring smooth
                  transportation and delivery.
                </p>

                <p>
                  With our extensive experience in the industry, we have the
                  knowledge and expertise to ensure that your products reach
                  their destination on time. By choosing our rail freight
                  services, you can effectively reduce transportation costs
                  while maintaining the reliability and efficiency of your
                  supply chain.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
