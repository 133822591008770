import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                About Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  <li className="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                   About Us
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                alt=""
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src="img/about 1.jpg" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                About Us
              </div>
              <h1 className="mb-4">
                We Don't Just Ship Cargo; We Deliver Confidence, Reliability,
                and Peace of Mind
              </h1>
              <p className="mb-4">
                <p>
                  Forge valuable connections for your business and stay at the
                  forefront of progress with {companyname}, your reliable
                  partner in the world of freight and forwarding. Seamlessly
                  navigate the complexities of supply chain management and seize
                  global opportunities. Join us in our mission to advance the
                  world together.
                </p>

                <p>
                  {companyname} adopts a comprehensive approach to supply chain
                  management that goes beyond just transportation. We stand by
                  our clients as trusted experts in freight forwarding, offering
                  guidance on international trade compliance and supplier
                  management expertise. This results in a complete solution that
                  optimizes their entire supply chain, leading to notable
                  improvements in efficiency, cost-effectiveness, and overall
                  success.
                </p>
              </p>
             
              <div className="d-flex align-items-center mt-4">
                <Link className="btn btn-primary rounded-pill px-4 me-3" to="/About">
                  Read More
                </Link>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
            {/* Case Start */}
            <div className="container-fluid bg-light py-5">
        <div className="container py-5">
          <div
            className="mx-auto text-center wow fadeIn"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
         What We Do
            </div>
            <h1 className="mb-4">Explore Our Freight Solutions</h1>
          </div>
          <div className="row g-4">
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Air">
                  <small>Air Freight</small>
                  <p className="lh-base text-white mb-3">
                  {companyname} is your ultimate choice for all your airfreight
                  cargo needs, offering secure, efficient, and reliable air...
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Inland Service.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Inland">
                  <small>Inland Service</small>
                  <p className="lh-base text-white mb-3">
                  Whether you require transportation by road, train, or barge,
                  we have your cargo needs covered. {companyname}...
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Ocean Freight.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Ocean">
                  <small>Ocean Freight</small>
                  <p className="lh-base text-white mb-3">
                  {companyname} serves as your gateway to growth, whether your
                  business is engaged in ocean export or...
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Rail Freight.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Rail">
                  <small>Rail Freight</small>
                  <p className="lh-base text-white mb-3">
                  Rail freight offers a cost-effective and convenient shipping
                  solution for your goods. However, concerns about...
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Break Bulk.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Break">
                  <small>Break Bulk</small>
                  <p className="lh-base text-white mb-3">
                  Our company excels in providing efficient Break Bulk Cargo
                  Handling Services. Break bulk refers to a transportation..
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Warehousing & Distribution.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Warehousing">
                  <small>Warehousing & Distribution</small>
                  <p className="lh-base text-white mb-3">
                  {companyname} offers scalable warehousing and distribution
                  services designed to assist businesses ..
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Case End */}

      <Footer />
    </>
  );
}
