import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Contact Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  <li className="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>
                 
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Contact Us
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                
                alt=""
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>
   
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div
            className="mx-auto text-center wow fadeIn"
            data-wow-delay="0.1s"
            style={{
              maxWidth: "500px",
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeIn",
            }}
          >
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
              Contact Us
            </div>
            <h1 className="mb-4">If You Have Any Query, Please Contact Us</h1>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <p className="text-center mb-4">
              By filling out the form, you'll stay informed about the latest shipping trends, updates, and promotions in the industry.
              </p>
              <div
                className="wow fadeIn"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeIn",
                }}
              >
                 <form
                id="contactForm"
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        className="form-control p-4 bg-light border-0"
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        className="form-control p-4 bg-light border-0"
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        className="form-control p-4 bg-light border-0"
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>{" "}
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        className="form-control p-4 bg-light border-0"
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <textarea
                        className="form-control p-4 bg-light border-0"
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                      defaultValue=""
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h5 style={{ color: "red" }}>{result}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact End */}
      <Footer />
    </>
  );
}
