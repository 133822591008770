import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      {/* Slider Area */}

      {/* About Start */}
      {/* Hero Start */}
      <div className="container-fluid mb-5 w3-banner jarallax">
        <video autoPlay loop muted>
          <source src="img/video.mp4"></source>
        </video>
      </div>
      {/* Hero End */}

      {/* About Start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src="img/about 1.jpg" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                About Us
              </div>
              <h1 className="mb-4">
                We Don't Just Ship Cargo; We Deliver Confidence, Reliability,
                and Peace of Mind
              </h1>
              <p className="mb-4">
                <p>
                  Forge valuable connections for your business and stay at the
                  forefront of progress with {companyname}, your reliable
                  partner in the world of freight and forwarding. Seamlessly
                  navigate the complexities of supply chain management and seize
                  global opportunities. Join us in our mission to advance the
                  world together.
                </p>

                <p>
                  {companyname} adopts a comprehensive approach to supply chain
                  management that goes beyond just transportation. We stand by
                  our clients as trusted experts in freight forwarding, offering
                  guidance on international trade compliance and supplier
                  management expertise. This results in a complete solution that
                  optimizes their entire supply chain, leading to notable
                  improvements in efficiency, cost-effectiveness, and overall
                  success.
                </p>
              </p>
             
              <div className="d-flex align-items-center mt-4">
                <Link className="btn btn-primary rounded-pill px-4 me-3" to="/About">
                  Read More
                </Link>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
            {/* Case Start */}
            <div className="container-fluid bg-light py-5">
        <div className="container py-5">
          <div
            className="mx-auto text-center wow fadeIn"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
         What We Do
            </div>
            <h1 className="mb-4">Explore Our Freight Solutions</h1>
          </div>
          <div className="row g-4">
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Air">
                  <small>Air Freight</small>
                  <p className="lh-base text-white mb-3">
                  {companyname} is your ultimate choice for all your airfreight
                  cargo needs, offering secure, efficient, and reliable air...
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Inland Service.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Inland">
                  <small>Inland Service</small>
                  <p className="lh-base text-white mb-3">
                  Whether you require transportation by road, train, or barge,
                  we have your cargo needs covered. {companyname}...
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Ocean Freight.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Ocean">
                  <small>Ocean Freight</small>
                  <p className="lh-base text-white mb-3">
                  {companyname} serves as your gateway to growth, whether your
                  business is engaged in ocean export or...
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Rail Freight.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Rail">
                  <small>Rail Freight</small>
                  <p className="lh-base text-white mb-3">
                  Rail freight offers a cost-effective and convenient shipping
                  solution for your goods. However, concerns about...
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Break Bulk.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Break">
                  <small>Break Bulk</small>
                  <p className="lh-base text-white mb-3">
                  Our company excels in providing efficient Break Bulk Cargo
                  Handling Services. Break bulk refers to a transportation..
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Warehousing & Distribution.jpg" alt="" />
                <Link className="case-overlay text-decoration-none" to="/Warehousing">
                  <small>Warehousing & Distribution</small>
                  <p className="lh-base text-white mb-3">
                  {companyname} offers scalable warehousing and distribution
                  services designed to assist businesses ..
                  </p>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Case End */}
      {/* Service Start */}
      <div className="container-fluid bg-light mt-5 py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Why Choose Us
              </div>
              <h1 className="mb-4">
              Your Journey, Our Responsibility
              </h1>
              <p className="mb-4">
             < p>One of the distinct advantages of our services is our ability to customize them to meet the unique requirements of each client. Serving as a one-stop solution, we offer a comprehensive package that covers all aspects of cargo freight forwarding operations.</p>

<p>Regardless of your industry, the goods you handle, or the primary markets you serve, our solutions empower businesses of all sizes to broaden their horizons.</p>
              </p>
              <Link className="btn btn-primary rounded-pill px-4" to="/About">
                Read More
              </Link>
            </div>
            <div className="col-lg-7">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-truck fa-2x" />
                        </div>
                        <h5 className="mb-3">SUPPLY CHAIN VISIBILITY

</h5>
                       
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-check fa-2x" />
                        </div>
                        <h5 className="mb-3">ORDER MANAGEMENT</h5>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-md-4">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-map-marker fa-2x" />
                        </div>
                        <h5 className="mb-3">REGIONAL HUB NETWORK</h5>
                        
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-globe fa-2x" />
                        </div>
                        <h5 className="mb-3">VALUE ADDED SERVICES</h5>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}




      {/* Team Start */}
      <div className="container-fluid bg-light py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Our Trading Services
              </div>
              <h1 className="mb-4">Explore Our Trading Services</h1>
             
            </div>
            <div className="col-lg-12">
              <div className="row g-4">
                <div className="col-md-4 pt-md-4">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Automotive.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Automotive</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Electronics.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Electronics</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="row g-4">
                  <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Special Cargo.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Special Cargo</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Aviation and AOG.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Aviation and AOG</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pt-md-4">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Pharmaceuticals.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Pharmaceuticals</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Cold logistics.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Cold logistics</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}

      <Footer />
    </>
  );
}
