import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
     
      {/* Footer Start */}
      <div className="container-fluid bg-dark text-white-50 footer pt-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-5 wow fadeIn" data-wow-delay="0.1s">
              <Link to="index.html" className="d-inline-block mb-3">
                <h3 className="text-white">
                 {companyname}
                </h3>
              </Link>
              <p className="mb-0">
              Forge valuable connections for your business and stay at the forefront of progress with Ibeesmart Enterprises, your reliable partner in the world of freight and forwarding. Seamlessly navigate the complexities of supply chain management and seize global opportunities. Join us in our mission to advance the world together.    </p>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
              <h5 className="text-white mb-4">Get In Touch</h5>
              <p>
                <i className="fa fa-map-marker-alt me-3" />
                {companyaddress}
              </p>
              <p>
                <i className="fa fa-phone-alt me-3" />
                {companynumber}
              </p>
              <p>
                <i className="fa fa-envelope me-3" />
                {companyemail}
              </p>
              
            </div>
            <div className="col-md-6 col-lg-2 wow fadeIn" data-wow-delay="0.5s">
              <h5 className="text-white mb-4">Popular Link</h5>
           
              <Link className="btn btn-link" to="/Home">
                Home
              </Link>

              <Link className="btn btn-link" to="/About">
                About Company
              </Link>
              <Link className="btn btn-link" to="/Whyus">
                Why Us
              </Link>

              <Link className="btn btn-link" to="/Services">
                Services
              </Link>

              <Link className="btn btn-link" to="/Trading">
                Trading solutions
              </Link>

              <Link className="btn btn-link" to="/Contact">
                Contact Us
              </Link>
              <Link className="btn btn-link" to="/Getquote">
                Request A Quote
              </Link>
            </div>
            <div className="col-md-6 col-lg-2 wow fadeIn" data-wow-delay="0.7s">
              <h5 className="text-white mb-4">Our Services</h5>
              <Link to="/Air" className="btn btn-link">
                    Air Freight
                    </Link>
                    <Link to="/Inland" className="btn btn-link">
                    Inland Service
                    </Link>
                    <Link to="/Ocean" className="btn btn-link">
                    Ocean Freight
                    </Link>
                    <Link to="/Rail" className="btn btn-link">
                    Rail Freight
                    </Link>
                    <Link to="/Break" className="btn btn-link">
                    Break Bulk
                    </Link>
                    <Link to="/Warehousing" className="btn btn-link">
                    Warehousing & Distribution
                    </Link>
            </div>
          </div>
        </div>
        <div className="container wow fadeIn" data-wow-delay="0.1s">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                ©2023{" "}
                <Link className="border-bottom" to="/">
                  {companyname}
                </Link>
                , All Right Reserved.
        
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <Link to="/">Home</Link>
                  <Link to="/Privacy">Privacy</Link>
                  <Link to="/Terms">Terms</Link>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </>
  );
};

export default Footer;
