import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Ocean Freight
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  <li className="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Ocean Freight
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                alt=""
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img
                  className="img-fluid"
                  src="img/Ocean Freight.jpg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4">Ocean Freight</h1>
              <p className="mb-4">
                <p>
                  {companyname} serves as your gateway to growth, whether your
                  business is engaged in ocean export or import activities. As a
                  prominent player in ocean freight logistics and a trusted
                  ocean freight provider and NVOCC (Non-Vessel Operating Common
                  Carrier), we distinguish ourselves through competitive pricing
                  and exceptional service quality. Our forward-thinking
                  strategies act as a vital bridge connecting your sales and
                  procurement markets, ensuring seamless cargo delivery to any
                  destination, regardless of its global location.
                </p>

                <p>
                  Depend on {companyname} for reliable and punctual ocean
                  freight services, a cornerstone of successful cargo transport.
                  Our extensive ocean logistics solutions ensure secure,
                  dependable, and cost-effective shipping from the point of
                  origin to destinations worldwide. We cater to diverse needs by
                  offering various equipment choices and consolidation services,
                  all while guaranteeing capacity and space availability for
                  routes to and from major ports across the globe.
                </p>

                <p>
                  As a leading ocean freight provider, we offer a diverse array
                  of services meticulously tailored to your unique requirements.
                  Our expert team possesses the knowledge and tools to navigate
                  the complex world of international shipping and logistics,
                  ensuring the timely and efficient delivery of your cargo to
                  its intended destination.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
