import { Outlet, Link } from "react-router-dom";
import Getquote from "../pages/Getquote";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  
  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };


  return (
    <>
      {/* Navbar Start */}
      <div className="container-fluid sticky-top bg-white">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark p-0">
            <Link to="/" className="navbar-brand">
              <img
                src="img/logo.png"
                style={{ height: "90px", padding: "5px" }}
              />
            </Link>
            <button
              type="button"
              className="navbar-toggler ms-auto me-0"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto">
                <Link to="/" className="nav-item nav-link active">
                  Home
                </Link>
                <Link to="/About" className="nav-item nav-link active">
                  Who We Are
                </Link>
                <Link to="/Whyus" className="nav-item nav-link active">
                  Why Us
                </Link>
                <Link to="/Trading" className="nav-item nav-link active">
                  Trading Solutions
                </Link>
                <div className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle active"
                    data-bs-toggle="dropdown"
                  >
                    What We Do
                  </Link>
                  <div className="dropdown-menu bg-light mt-2">
                    <Link to="/Air" className="dropdown-item">
                    Air Freight
                    </Link>
                    <Link to="/Inland" className="dropdown-item">
                    Inland Service
                    </Link>
                    <Link to="/Ocean" className="dropdown-item">
                    Ocean Freight
                    </Link>
                    <Link to="/Rail" className="dropdown-item">
                    Rail Freight
                    </Link>
                    <Link to="/Break" className="dropdown-item">
                    Break Bulk
                    </Link>
                    <Link to="/Warehousing" className="dropdown-item">
                    Warehousing & Distribution
                    </Link>
                  </div>
                </div>
                <Link to="/Contact" className="nav-item nav-link active">
                  Contact
                </Link>
              </div>
              <Link className="btn btn-primary rounded-pill px-4 me-3" to="/Getquote">Request For Quote</Link>
            </div>
          </nav>
        </div>
      </div>
      {/* Navbar End */}
    </>
  );
};

export default Header;
