import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Air Freight
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  <li className="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Air Freight
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                alt=""
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img
                  className="img-fluid"
                  src="img/Air Freight.jpg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4">Air Freight</h1>
              <p className="mb-4">
                <p>
                  {companyname} is your ultimate choice for all your airfreight
                  cargo needs, offering secure, efficient, and reliable air
                  cargo services worldwide.
                </p>

                <p>
                  We have earned a reputation built on reliability and
                  experience, and we offer a diverse array of meticulously
                  crafted air cargo solutions tailored to your specific demands.
                  Our international air cargo services are strategically
                  designed to ensure the fastest delivery of your goods to their
                  ultimate destination.
                </p>

                <p>
                  At {companyname}, we understand the paramount importance of
                  timely deliveries, which drives us to provide unparalleled air
                  freight delivery services. Our team is highly skilled in
                  handling a diverse range of air cargo services, including
                  temperature-controlled and frozen air cargo, ensuring that
                  your goods are transported under optimal conditions.
                </p>

                <p>
                  Our comprehensive air freight shipping services cater to both
                  domestic and international air freight, establishing us as
                  your foremost choice for all your air cargo needs. We offer
                  international cargo services tailored to accommodate
                  businesses of all sizes, guaranteeing prompt and impeccable
                  delivery of your goods to their final destination.
                </p>

                <p>
                  At {companyname}, we collaborate with meticulously selected
                  carriers and follow meticulously planned schedules along major
                  global routes. This provides our clientele with unwavering
                  planning assurance and heightened operational efficiency. We
                  offer a portfolio of highly adaptable products, allowing you
                  to select the delivery pace that best aligns with your
                  specific requirements.
                </p>

                <p>
                  Our expertise lies in delivering secure, dependable, and
                  efficient air transport services dedicated to time-sensitive,
                  high-value cargo. We leverage our proprietary-controlled
                  freighter network and strategic affiliations to swiftly and
                  effectively transport goods across the globe. Utilizing
                  advanced technology and exhaustive checklists, we vigilantly
                  monitor cargo from its inception to its ultimate destination,
                  prioritizing cargo security and protection above all else.
                  Additionally, we offer access to global gateways and
                  streamlined ground handling processes, empowering our clients
                  to maintain control over their supply chains while
                  simultaneously reducing overall costs.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
