import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Why Choose Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  <li className="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Why Choose Us
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                alt=""
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light mt-5 py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Why Choose Us
              </div>
              <h1 className="mb-4">
              Your Journey, Our Responsibility
              </h1>
              <p className="mb-4">
             < p>One of the distinct advantages of our services is our ability to customize them to meet the unique requirements of each client. Serving as a one-stop solution, we offer a comprehensive package that covers all aspects of cargo freight forwarding operations.</p>

<p>Regardless of your industry, the goods you handle, or the primary markets you serve, our solutions empower businesses of all sizes to broaden their horizons.</p>
              </p>
              <Link className="btn btn-primary rounded-pill px-4" to="/About">
                Read More
              </Link>
            </div>
            <div className="col-lg-7">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-truck fa-2x" />
                        </div>
                        <h5 className="mb-3">SUPPLY CHAIN VISIBILITY

</h5>
                       
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-check fa-2x" />
                        </div>
                        <h5 className="mb-3">ORDER MANAGEMENT</h5>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-md-4">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-map-marker fa-2x" />
                        </div>
                        <h5 className="mb-3">REGIONAL HUB NETWORK</h5>
                        
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <i className="fa fa-globe fa-2x" />
                        </div>
                        <h5 className="mb-3">VALUE ADDED SERVICES</h5>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}




      {/* Team Start */}
      <div className="container-fluid bg-light py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
                Our Trading Services
              </div>
              <h1 className="mb-4">Explore Our Trading Services</h1>
             
            </div>
            <div className="col-lg-12">
              <div className="row g-4">
                <div className="col-md-4 pt-md-4">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Automotive.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Automotive</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Electronics.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Electronics</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="row g-4">
                  <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Special Cargo.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Special Cargo</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Aviation and AOG.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Aviation and AOG</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pt-md-4">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Pharmaceuticals.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Pharmaceuticals</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="team-item bg-white text-center rounded pb-4 pt-0">
                        <img
                          className="img-fluid pb-4"
                          src="img/Cold logistics.jpg"
                          alt=""
                        />
                        <Link to="/Trading">
                        <h5 className="mb-0">Cold logistics</h5>
                        <small>Trading Service</small>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}

      <Footer />
    </>
  );
}
