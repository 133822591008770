import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Inland Service
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  <li className="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Inland Service
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                alt=""
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img
                  className="img-fluid"
                  src="img/Inland Service.jpg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4">Inland Service</h1>
              <p className="mb-4">
                <p>
                  Whether you require transportation by road, train, or barge,
                  we have your cargo needs covered. {companyname} offers
                  comprehensive transportation solutions for global cargo,
                  including a range of inland transport options. Our inland
                  transport network seamlessly extends our shipping services,
                  providing door-to-door transportation via train, barge, or
                  truck through partnerships with reliable global carriers. We
                  specialize in intermodal transportation and offer customized
                  solutions for dry cargo, refrigerated cargo, dangerous cargo,
                  or break bulk, ensuring secure and timely delivery.
                </p>

                <p>
                  Our dedicated Global Inland team specializes in tailor-made
                  solutions for the last mile, bridging the gap between sea and
                  land using trucks, trains, and barges on a worldwide scale.
                  With an extensive network of inland terminals and depots, we
                  provide intermodal logistics services designed to meet unique
                  requirements, simplifying the optimization efforts of your
                  transport chain while maximizing global transport efficiency
                  and sustainability. We also aim to minimize coordination and
                  communication efforts, reduce carbon footprints, and offer
                  extensive coverage.
                </p>

                <p>
                  {companyname} guarantees the safe, reliable, and sustainable
                  transportation of your cargo with personalized communication
                  through a single entry point. We offer environmentally
                  friendly transport solutions and actively work to reduce your
                  cargo's carbon footprint through optimized planning, mode
                  switching, and the utilization of renewable fuels and
                  electricity. Our dedicated teams handle every aspect of your
                  cargo's journey, including compliance considerations covering
                  legal, social, and environmental aspects. Additionally, we
                  provide supplementary services such as pick-up and drop-off,
                  inland storage, demurrage and detention benefits, as well as
                  track-and-trace services.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
