import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Warehousing & Distribution
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  <li className="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Warehousing & Distribution
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                alt=""
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img
                  className="img-fluid"
                  src="img/Warehousing & Distribution.jpg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4">Warehousing & Distribution</h1>
              <p className="mb-4">
                <p>
                  {companyname} offers scalable warehousing and distribution
                  services designed to assist businesses in optimizing the
                  efficiency of their supply chain and expediting their product
                  entry into the market. Our facilities are characterized by
                  flexibility, resilience, and connectivity to pivotal markets
                  and transportation modes. We provide additional value-added
                  services, including scanning, sorting, and labeling,
                  complemented by local expertise supported by a global network.
                  Outsourcing warehousing and distribution operations to{" "}
                  {companyname} can assist businesses in simplifying operations,
                  accelerating time-to-market, and minimizing asset-related
                  overhead costs.
                </p>

                <p>
                  The company extends global warehousing services encompassing
                  bonded and non-bonded warehouses, consolidation centers,
                  deconsolidation centers, and fulfillment centers. Our array of
                  value-added services comprises quality control, re-packing,
                  returns management, and product disposal, all underpinned by
                  cutting-edge Warehouse and Inventory Management Systems.
                  Strategically positioned in proximity to sourcing hubs, ports,
                  and major shipping routes, our facilities enable swift cargo
                  throughput.
                </p>

                <p>
                  Our distribution services provide a seamless fulfillment
                  solution by combining order processing with an array of
                  delivery options, including Full Truck Loads (FTL) and Less
                  than Truck Loads (LTL). FTL proves advantageous for sizable
                  shipments, facilitating economies of scale and direct
                  deliveries to the final destination. Meanwhile, LTL offers a
                  cost-effective choice for smaller freight, reducing handling
                  costs and minimizing the risk of damage during transit.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
