import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid pt-5 bg-primary hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Break Bulk
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                  <li className="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Break Bulk
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                alt=""
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img
                  className="img-fluid"
                  src="img/Break Bulk.jpg"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4">Break Bulk</h1>
              <p className="mb-4">
                <p>
                  Our company excels in providing efficient Break Bulk Cargo
                  Handling Services. Break bulk refers to a transportation
                  method in which goods are shipped individually rather than
                  being contained within a standardized container. This type of
                  cargo includes items transported in crates, bags, boxes,
                  drums, barrels, or any other packaging that doesn't require a
                  container. Typically, break bulk cargoes are characterized by
                  their large size and dimensions.
                </p>

                <p>
                  We specialize in handling various types of break bulk cargo,
                  such as oil and gas equipment, windmills, yachts, oversized
                  vehicles, boats, cranes, turbine blades, ship propellers,
                  generators, large engines, and construction equipment, among
                  others. Our team of experienced professionals ensures the
                  flawless execution of our Break Bulk Cargo Handling Service,
                  meeting the highest standards of excellence. We leverage
                  modern applications and tools to tailor this service to the
                  specific demands of our valued clients.
                </p>

                <p>
                  Here are some compelling reasons why opting for break bulk is
                  advantageous for your logistics needs:
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
